import axios from 'axios';
import * as workerTimers from 'worker-timers';
import Dexie from 'dexie';
import { get } from 'lodash';
import { byteLength } from 'byte-length';
import { Plugins, Capacitor } from '@capacitor/core';
import { removeError, cachedErrorsToRedux, connectWS } from './actions';
import createSocketConnection from './websocket';
import { firebaseTokenListener } from './capacitorInit';

const { PushNotifications } = Plugins;

export const shellDB = new Dexie('vt-shell');
shellDB.version(1).stores({
  errors: '&id, errors',
});

const cacheToRedux = async (dispatch) => {
  const cached = await shellDB.errors.get({ id: 'errors' });
  if (cached && cached.errors && cached.errors.length > 0) {
    await dispatch(cachedErrorsToRedux(cached.errors));
  }
};

const sendLogsToPapertrail = async (dispatch, getState) => {
  try {
    // first check cache
    await cacheToRedux(dispatch);
    const errors = get(getState(), 'shell.errors', []);
    if (errors.length > 0) {
      const sent = errors.map((error) => {
        const errorMsg = encodeURI(`${error.err}`);
        return axios({
          url:
            'https://jfj2zfyltnfhho5xmksmzq5w6u.appsync-api.eu-central-1.amazonaws.com/graphql',
          method: 'post',
          withCognitoAuth: true,
          disableLoading: true,
          errId: error.id,
          data: {
            query: `query {
              logToPapertrail(
                sourceApp: "${error.sourceApp}",
                partOfApp: "${error.partOfApp}",
                message: "${errorMsg}",
              )
            }`,
          },
        });
      });

      const res = await axios.all(sent);
      res.map((r) => {
        const success = get(r, 'data.data.logToPapertrail', false);
        if (success) {
          const id = get(r, 'config.errId', null);
          // remove error from redux
          dispatch(removeError(id));
          return null;
        }
        // leave error
        return null;
      });
    }
  } catch (e) {
    cacheToRedux(dispatch);
  }
};

const checkLogSize = async (dispatch, getState) => {
  try {
    // first check cache
    await cacheToRedux(dispatch);
    const errors = get(getState(), 'shell.errors', []);
    // if error size is greater than 1kb send to papertrail
    if (byteLength(errors) > 1000) {
      sendLogsToPapertrail(dispatch, getState);
    }
  } catch (e) {
    cacheToRedux(dispatch);
  }
};

let sendLogsTimer = null;
let checkLogSizeTimer = null;

export const start = async (store) => {
  const { dispatch, getState } = store;
  if (dispatch) {
    const checkWebsocketConnected = get(getState(), 'shell.websocket', false);

    store.subscribe(firebaseTokenListener);
    if (!checkWebsocketConnected && !Capacitor.isNative) {
      createSocketConnection(async (rws) => {
        const socket = await rws;
        dispatch(connectWS(socket));
      });
    }
    if (Capacitor.isNative) {
      // init firebase token request
      PushNotifications.requestPermission().then((result) => {
        if (result.granted) {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        } else {
          // Show some error
        }
      });
    }

    await sendLogsToPapertrail(dispatch, getState);
    sendLogsTimer = workerTimers.setInterval(
      () => Promise.all([sendLogsToPapertrail(dispatch, getState)]),
      // send logs if present every 12 hours
      // 43200000,
      900000, // 15 minutes
    );

    checkLogSizeTimer = workerTimers.setInterval(
      () => Promise.all([checkLogSize(dispatch, getState)]),
      // check log size every 6 hours
      21600000,
    );
  }
};

export const stop = () => {
  workerTimers.clearInterval(sendLogsTimer);
  workerTimers.clearInterval(checkLogSizeTimer);
};

export default {
  start,
  stop,
  shellDB,
};
