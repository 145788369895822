import axios from 'axios';
import cognito from '../auth/cognito';

let interceptorId;

const AWSRequestInterceptor = async (config) => {
  if (config.withCognitoAuth) {
    await cognito.refreshSession().catch(() => null);
    const cognitoUser = cognito.userPool.getCurrentUser();

    if (cognitoUser) {
      const JWT = await new Promise((resolve, reject) => {
        cognitoUser.getSession((err, data) => {
          if (err) return reject(err);
          const token = data.getIdToken().jwtToken;
          return resolve(token);
        });
      });

      const configWithAuth = {
        ...config,
        headers: {
          Authorization: JWT,
        },
      };

      return configWithAuth;
    }
  }

  return config;
};

export const addInterceptor = () => {
  interceptorId = axios.interceptors.request.use(AWSRequestInterceptor);
};

export const removeInterceptor = () => {
  axios.interceptors.request.eject(interceptorId);
};
