import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { IntlProvider as TSIntlProvider } from 'react-intl-redux';
import { get } from 'lodash';
import { createLogger } from 'redux-logger';
import videoConferencing from './home';
import { initVideoConferencing } from './shell/actions';
// import AvatarLib from '@virtaskdev/avatar';

// import Dexie from 'dexie';
import rootReducer from './reducer';

import App from './app';
import Auth from './auth';
import history from './history';
import modules from './modules';
import { addInterceptor } from './lib/AWSAxios';
// import dispatchErrorToRedux from './shell/dispatchErrorToRedux';
import i18n from './i18n';

// import registerServiceWorker from './registerServiceWorker';
import AppSync from './appsync';

import './shell/capacitorInit';
import { checkOnlineStatusAndBoot } from './utils';

// import './index.css';

// registerServiceWorker();

// const windowIfDefined = typeof window === 'undefined' ? null : (window as any);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let middleware;
if (process.env.NODE_ENV !== 'production') {
  const logger = createLogger({ collapsed: true });
  middleware = applyMiddleware(thunk, logger);
} else {
  middleware = applyMiddleware(thunk);
}

export const store = createStore(rootReducer, composeEnhancers(middleware));

const render = async (Component) => {
  // when react-intl-redux is updated we can probobly remove the any type
  const IntlProvider = TSIntlProvider;
  ReactDOM.render(
    <div id="rootChild">
      <Provider store={store}>
        <IntlProvider>
          <Router>
            <div>
              <CssBaseline />
              <Component />
            </div>
          </Router>
        </IntlProvider>
      </Provider>
    </div>,
    document.getElementById('root'),
  );
};

const appSyncListener = async () => {
  // listen to lastActionType as not to have an endless loop
  const lastActionType = get(
    store.getState(),
    'appsync.lastActionListener.type',
    null,
  );

  const disableListener = get(
    store.getState(),
    'appsync.lastActionListener.disableListener',
    null,
  );
  // const onboardState = true;
  // const onboardState = localStorage.getItem('onboarded');
  if (lastActionType === 'appsync/SETTINGS/SUCCESS' && !disableListener) {
    // const currentState = get('appsync.lastActionListener.userSettings.modules', store.getState(), []);
    const enabledModules = get(
      store.getState(),
      'appsync.lastActionListener.userSettings.modules',
      [],
    );

    const shellLang =
      get(
        store.getState(),
        'appsync.lastActionListener.userSettings.core.selectedShellLang',
        // fall back to application default locale
        process.env.REACT_APP_LOCALE,
      ) || process.env.REACT_APP_LOCALE;

    await i18n(shellLang);

    window.document
      .querySelector('html')
      .setAttribute('lang', shellLang || process.env.REACT_APP_LOCALE);

    const videoConferencingInitialized = store.getState()?.shell
      ?.videoConferencingInitialized;

    if (enabledModules) {
      if (
        enabledModules.includes(videoConferencing.ID) &&
        !videoConferencingInitialized
      ) {
        modules.getModules(true, store, enabledModules);
        store.dispatch(initVideoConferencing());
      }

      history.push('/video-conferencing');

      // if (!(onboardState || process.env.REACT_APP_SKIP_ONBOARDING === 'true')) {
      //   history.push('/onboarding/landing');
      // } else {
      // }
      // // localStorage.setItem('onboarded', 'true')
      render(App.Component);
    }
  }
};

const boot = async () => {
  const status = Auth.selectors.getAuthStatus();
  // console.log(status);
  // ToDo: make it wait for avatar before rendering

  // try {
  //   AvatarLib.connect(
  //     {
  //       host: process.env.REACT_APP_AVATAR_HOST || 'localhost',
  //       port: process.env.REACT_APP_ASR_PORT || 7980,
  //     },
  //     {
  //       host: process.env.REACT_APP_AVATAR_HOST || 'localhost',
  //       port: process.env.REACT_APP_ASR_PORT || 7981,
  //     },
  //   );
  // } catch (e) {
  //   console.error(e);
  // }

  AppSync.start({ dispatch: store.dispatch, disableListener: false });

  if (!status) {
    await i18n(process.env.REACT_APP_LOCALE);
    history.push('/auth/login');
    render(App.Component);
  } else {
    addInterceptor(); // enable axios auth interceptor
    // eslint-disable-next-line
    store.subscribe(appSyncListener); // maybe export unsubscribe to be used in some later stage
  }
};

checkOnlineStatusAndBoot(boot);

export default {};
