import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

class LoginResult extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    const { onRetry } = this.props;

    return onRetry();
  };

  catchEnter = (e) => {
    if (e.key === 'Enter') {
      this.handleSubmit(e);
    }
  };

  render() {
    const { err, errCode } = this.props;

    return (
      <form id="dialogFormId" onSubmit={this.handleSubmit}>
        <Dialog
          type="submit"
          form="dialogFormId"
          open
          onKeyPress={this.catchEnter}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            form="dialogFormId"
            type="submit"
            id="alert-dialog-title"
          >
              <FormattedMessage
                id="auth.loginFail"
                defaultMessage="!!auth.loginFail"
                description="Login fail title"
              />
          </DialogTitle>
          <DialogContent form="dialogFormId" type="submit">
            <DialogContentText
              form="dialogFormId"
              type="submit"
              id="alert-dialog-description"
            >
                <FormattedMessage
                  id={`auth.error.${errCode}`}
                  defaultMessage={err}
                  description="Error message"
                />
            </DialogContentText>
          </DialogContent>
          <DialogActions form="dialogFormId" type="submit">
              <Button
                name="retry"
                type="submit"
                form="dialogFormId"
                color="primary"
              >
                <FormattedMessage
                  id="auth.retry"
                  defaultMessage="!!auth.retry"
                  description="Retry"
                />
              </Button>
          </DialogActions>
        </Dialog>
      </form>
    );
  }
}

export default LoginResult;
