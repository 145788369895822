// import db from 'client/db';
import cognito from './cognito';

// const cache = {};

const getAuthStatus = () => cognito.userPool.getCurrentUser();

const getProfile = async () => {};
// const tokens = await getAuthStatus();
// const profile = await new Promise((resolve, reject) => {
//   auth0.getUserInfo(tokens.accessToken, (err, prof) => {
//     if (err) return reject(err);
//     return resolve(prof);
//   });
// });

// ({});

const getAPIKeys = async () => {
  // const tokens = await getAuthStatus();
  // if (cache.apiKeys) {
  //   return cache.apiKeys;
  // }
  // console.log(tokens);
  // const res = await axios.post('https://api.virtask.nl/auth/token', {
  //   authResponse: { access_token: tokens.accessToken },
  // });
  // cache.apiKeys = res.data;
  // return res.data;
};

const getUserSettings = async () => {
  // if (cache.settings) {
  //   return cache.settings;
  // }
  // const keys = await getAPIKeys();
  // console.log(keys);
  // const settings = await axios.get('https://api.virtask.nl/user/profile', {
  //   headers: {
  //     key: cache.apiKeys.key,
  //     secret: cache.apiKeys.key,
  //   },
  // });
  // const res = await axios.get('http://localhost:8989/settings');
  // cache.settings = res.data;
  // return res.data;
};

export default {
  getAuthStatus,
  getProfile,
  getAPIKeys,
  getUserSettings,
};
