import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Route, Switch } from 'react-router-dom';

import { Plugins, Capacitor } from '@capacitor/core';
import theme from '../Theme';
import Shell from '../shell';
import Auth from '../auth';

import ErrorBoundary from '../shell/components/ErrorBoundary';

const { SplashScreen } = Plugins;

const App = () => {
  React.useEffect(() => {
    if (Capacitor.isNative) {
      SplashScreen.hide();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <ErrorBoundary>
          <Switch>
            <Route key="auth" path="/auth" component={Auth.Component} />
            <Route
              key="video-conferencing"
              path="/video-conferencing"
              component={Shell.Component}
            />
          </Switch>
        </ErrorBoundary>
      </MuiThemeProvider>
    </div>
  );
};

export default App;
