import React from 'react'; // eslint-disable-line
import dispatchErrorToRedux from '../dispatchErrorToRedux';

import Auth from '../../auth';

const hashCode = (str) => {
  let hash = 0;
  let i;
  let chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

let session = {};

const status = Auth.selectors.getAuthStatus();
// const { username } = status;
// const hash = btoa(hashCode(username));
if (status) {
  const username = status.getUsername();
  const hash = btoa(`${hashCode(username)}`);

  session = {
    userId: hash,
    sessionId: `${new Date().getTime()}-${hash}`,
    sensorId: `AnneNext-${hash}`,
  };
}

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    });

    // You can also log error messages to an error reporting service here

    dispatchErrorToRedux({
      err: `${error.message} ${error.stack} ${errorInfo.componentStack}`,
      sourceApp: 'anne',
      partOfApp: 'react-dom',
    });
  }

  render() {
    const { errorInfo, error } = this.state;
    const { children } = this.props;
    if (errorInfo) {
      // Error path
      console.warn([error, errorInfo]);
      return (
        <div>
          <h2>Something went wrong.</h2>
          <p>
            {/* {`Version: ${pkg.version}`} */}
            {/* <br /> */}
            {Object.keys(session).length > 0 && `User ID: ${session.userId}`}
            <br />
            {Object.keys(session).length > 0 &&
              `Session ID: ${session.sessionId}`}
            <br />
            {Object.keys(session).length > 0 &&
              `Sensor ID: ${session.sensorId}`}
          </p>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            <br />
            {error && error.toString()}
            <br />
            {errorInfo.componentStack}
          </details>
        </div>
      );
    }
    // Normally, just render children
    return children;
  }
}
