import axios from 'axios';
import Dexie from 'dexie';
import { get, isEmpty } from 'lodash';
import cognito from '../auth/cognito';
import { getAllUserSettingsSuccess } from './actions';

const db = new Dexie('vt-appsync');

db.version(1).stores({
  userSettings: '&id, userSettings',
});

const getUserSettings = ({ username }) => `query {
  getUserSettings(username: "${username}")
}`;

const dispatchCachedSettings = async ({ dispatch, disableListener }) => {
  const data = await db.userSettings.get({
    id: 'userSettings',
  });
  const cachedUserSettings = get(data, 'userSettings');
  if (!isEmpty(cachedUserSettings)) {
    dispatch(getAllUserSettingsSuccess(cachedUserSettings, disableListener));
  }
};

const runGraphql = async ({ dispatch, disableListener }) => {
  await cognito.refreshSession().catch(() => null);

  const cognitoUser = cognito.userPool.getCurrentUser();

  if (cognitoUser) {
    // next line always refreshes token
    cognitoUser.getSession(async (err, data) => {
      if (err) {
        dispatchCachedSettings({ dispatch, disableListener });
        return;
      }

      const jwtIdToken = data.getIdToken().jwtToken;

      try {
        const res = await axios({
          url:
            'https://jfj2zfyltnfhho5xmksmzq5w6u.appsync-api.eu-central-1.amazonaws.com/graphql',
          method: 'post',
          headers: { Authorization: jwtIdToken },
          data: {
            query: getUserSettings({ username: cognitoUser.username }),
          },
        });

        const userSettings = JSON.parse(
          get(res, 'data.data.getUserSettings', null),
        );

        if (userSettings) {
          db.userSettings.put({ id: 'userSettings', userSettings });
          dispatch(getAllUserSettingsSuccess(userSettings, disableListener));
        }
      } catch (error) {
        dispatchCachedSettings({ dispatch, disableListener });
        console.error(error);
      }
    });
  }
};

const start = ({ dispatch, disableListener }) => {
  runGraphql({ dispatch, disableListener });
};

export { start }; // eslint-disable-line
