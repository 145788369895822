export const SIGNUP_SUCCESS = 'auth/SIGNUP/SUCCESS';
export const SIGNUP_FAIL = 'auth/SIGNUP/FAIL';
export const LOGIN_SUCCESS = 'auth/LOGIN/SUCCESS';
export const LOGIN_FAIL = 'auth/LOGIN/FAIL';

export default {
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
};
