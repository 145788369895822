import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#ffad42',
      main: '#f57c00',
      dark: '#bb4d00',
      contrastText: '#000',
    },
    secondary: {
      light: '#bd9b8f',
      main: '#8c6d62',
      dark: '#5e4238',
      contrastText: '#fff',
    },
  },
  typography: {
    useNextVariants: true,
  },
});

export default theme;
