import {
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
} from './actionTypes';

export const signupSuccess = (res) => ({
  type: SIGNUP_SUCCESS,
  res,
});

export const signupFail = (err) => ({
  type: SIGNUP_FAIL,
  err,
});

export const loginSuccess = (formData) => ({
  type: LOGIN_SUCCESS,
  formData,
});

export const loginFail = (err) => ({
  type: LOGIN_FAIL,
  err,
});

export default {
  signupSuccess,
  signupFail,
  loginSuccess,
  loginFail,
};
