import { combineReducers } from 'redux'; //eslint-disable-line

import { USER_DATA_SUCCESS, USER_DATA_FAIL } from './actionTypes';

const lastActionListener = (state = null, action) => action;

const initialState = {};

export default combineReducers({
  appSyncData: (state = initialState, action) => {
    switch (action.type) {
      case USER_DATA_FAIL:
        return {};
      case USER_DATA_SUCCESS:
        return { ...state, userSettings: action.userSettings };
      default:
        return { ...state };
    }
  },
  lastActionListener,
});
