import PersistentWebSocket from 'pws';
import axios from 'axios';
import { store } from '../index';

import { websocketConnected, websocketFailed } from './actions';
import AppSync from '../appsync';
import cognito from '../auth/cognito';

const cognitoUser = cognito.userPool.getCurrentUser();

export const urlProvider = async ({ firebaseRegistrationToken }) => {
  try {
    await cognito.refreshSession().catch(() => null);
    const JWT = await new Promise((resolve, reject) => {
      if (!cognitoUser) {
        // eslint-disable-next-line
        reject(null);
      }
      cognitoUser.getSession((err, data) => {
        if (err) {
          // eslint-disable-next-line
          reject(null);
        }
        if (data && data.getAccessToken) {
          const token = data.getAccessToken().jwtToken;
          resolve(token);
        }
        // eslint-disable-next-line
        reject(null);
      });
    });

    return `wss://3s8gydchaa.execute-api.eu-central-1.amazonaws.com/prod?token=${JWT}&firebaseRegistrationToken=${
      firebaseRegistrationToken || ''
    }`;
  } catch (err) {
    return null;
  }
};
let stopPing = () => null;

const waitForSocket = (rws, firebaseRegistrationToken) =>
  new Promise((resolve, reject) => {
    rws.addEventListener('open', () => {
      stopPing = setInterval(
        () => rws.sendStringified({ action: 'FPing' }),
        15000,
      );
      store.dispatch(websocketConnected());
      resolve(rws);
    });

    rws.addEventListener('close', async () => {
      console.log('closed');
      const wsUrl = await urlProvider({ firebaseRegistrationToken }).catch(
        () => null,
      );
      if (wsUrl) {
        rws.url = wsUrl; // eslint-disable-line
      }

      clearInterval(stopPing);
      store.dispatch(websocketFailed());
      reject(null);
    });
  });

const createReconnectionWS = async ({ firebaseRegistrationToken }) => {
  const fetchAndPurgeMessageQueue = async () =>
    axios({
      url:
        'https://jfj2zfyltnfhho5xmksmzq5w6u.appsync-api.eu-central-1.amazonaws.com/graphql',
      withCognitoAuth: true,
      method: 'POST',
      data: {
        query: `query {
        fetchAndPurgeMessageQueue
      }`,
      },
    });
  fetchAndPurgeMessageQueue();
  const wsUrl = await urlProvider({ firebaseRegistrationToken });

  const rws = new PersistentWebSocket(wsUrl, {
    pingTimeout: 30 * 1000, // Reconnect if no message received in 30s.
  });
  rws.emit = (msg) => rws.send(JSON.stringify(msg));
  rws.sendStringified = (msg) => rws.send(JSON.stringify(msg));

  const getEventObject = (str) => {
    try {
      return JSON.parse(str);
    } catch (e) {
      return {};
    }
  };

  const updatingRules = {
    settings: () => {
      AppSync.start({ dispatch: store.dispatch });
    },
  };

  rws.addEventListener('message', (event) => {
    const message = getEventObject(event.data);
    if (message === 'pong') {
      return;
    }
    const { updated, updated: updatedModule } = message;

    if (!updated || !updatingRules[updatedModule]) return;

    updatingRules[updatedModule]({ message });
  });
  await waitForSocket(rws, firebaseRegistrationToken);

  return rws;
};

const creator = async (onCreated, firebaseRegistrationToken) => {
  const rws = await createReconnectionWS({ firebaseRegistrationToken });
  onCreated(rws);
};

export default creator;
