import Dexie from 'dexie';
import isOnline from 'is-online';

const db = new Dexie('vt-lang');

db.version(1).stores({
  locale: '&id, locale',
});

export const removeCache = async () => {
  localStorage.clear();
  try {
    const DBs = await Dexie.getDatabaseNames();
    DBs.forEach(async (dbs) => {
      const tempDB = new Dexie(dbs);
      if (tempDB.name === 'vt-lang') {
        return null;
      }

      await tempDB.delete();
    });

    const cacheKeys = await caches.keys();

    if (cacheKeys) {
      cacheKeys.forEach((chName) => caches.delete(chName));
    }
  } catch (err) {}
};

export const checkOnlineStatusAndBoot = async (boot) => {
  const online = await isOnline();

  if (online) {
    boot();
    return;
  }

  const intervalId = setInterval(async () => {
    const hasInternet = await isOnline();

    if (!hasInternet) {
      return;
    }
    boot();
    clearInterval(intervalId);
  }, 4000);
};
