const TOGGLE_MICROPHONE = 'microphone/TOGGLE';
const SHOW_NOTIFICATION = 'notifications/SHOW';
const EXPIRE_NOTIFICATION = 'notifications/EXPIRE';
const CONFIRM_NOTIFICATION = 'notifications/CONFIRM';
const CANCEL_NOTIFICATION = 'notifications/CANCEL';
const SHOW_LOADING_BAR = 'loading/SHOW';
const HIDE_LOADING_BAR = 'loading/HIDE';
const SHOW_CENTER_MODAL = 'shell/SHOW_MODAL';
const HIDE_CENTER_MODAL = 'shell/HIDE_MODAL';
const ASR_TELL_TIME = 'shell/ASR/TELL_TIME';
const ASR_TELL_DATE = 'shell/ASR/TELL_DATE';
const LOG_ERROR = 'shell/LOG_ERROR';
const CACHED_ERRORS = 'shell/CACHED_ERRORS';
const REMOVE_ERROR = 'shell/REMOVE_ERROR';
const WS_CONNECTED = 'shell/WS_CONNECTED';
const WS_DISCONNECTED = 'shell/WS_DISCONNECTED';
const TELEMETRY_RECORD = 'telemetry/RECORD';
const VIDEO_CONFERENCING_INITIALIZED = 'shell/VIDEO_CONFERENCING_INITIALIZED';
const WEBSOCKET_CONNECTED = 'shell/WEBSOCKET_CONNECTED';
const WEBSOCKET_FAILED = 'shell/WEBSOCKET_FAILED';
const FIREBASE_TOKEN = 'capactior/FIREBASE_TOKEN';

export default {
  VIDEO_CONFERENCING_INITIALIZED,
  CACHED_ERRORS,
  REMOVE_ERROR,
  LOG_ERROR,
  TOGGLE_MICROPHONE,
  SHOW_NOTIFICATION,
  EXPIRE_NOTIFICATION,
  CONFIRM_NOTIFICATION,
  CANCEL_NOTIFICATION,
  SHOW_LOADING_BAR,
  HIDE_LOADING_BAR,
  SHOW_CENTER_MODAL,
  HIDE_CENTER_MODAL,
  ASR_TELL_TIME,
  ASR_TELL_DATE,
  TELEMETRY_RECORD,
  WEBSOCKET_CONNECTED,
  WEBSOCKET_FAILED,
  WS_CONNECTED,
  WS_DISCONNECTED,
  FIREBASE_TOKEN,
};
