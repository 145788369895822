import axios from 'axios';
import { get } from 'lodash';
import { showLoadingBar, hideLoadingBar } from './actions';
/*
If you want to disable loading bar for some axios requests,
just add 'disableLoading: false' to the wanted axios config.

Example:

axios.get(
  'http://some.url',
  { disableLoading: false },
)

Read more about axios interceptors in official axios docs
https://github.com/axios/axios
*/

const initLoading = (dispatch) => {
  const checkDisabled = (action, isDisabled, returnedConfigOrError) => {
    if (isDisabled) {
      return returnedConfigOrError;
    }
    dispatch(action());
    return returnedConfigOrError;
  };

  axios.interceptors.request.use(
    (config) => checkDisabled(showLoadingBar, config.disableLoading, config),
    (error) =>
      checkDisabled(
        showLoadingBar,
        get(error, 'config.disableLoading', true),
        Promise.reject(error),
      ),
  );
  axios.interceptors.response.use(
    (response) =>
      checkDisabled(hideLoadingBar, response.config.disableLoading, response),
    (error) =>
      checkDisabled(
        hideLoadingBar,
        get(error, 'config.disableLoading', true),
        Promise.reject(error),
      ),
  );
};

export const applyModuleAxiosInterceptors = (module) => {
  axios.interceptors.request.handlers.forEach((handler) => {
    const requestInterceptor = get(
      module,
      'moduleAxios.interceptors.request',
      null,
    );
    if (requestInterceptor) {
      requestInterceptor.use(
        handler.fulfilled,
        get(handler, 'rejected', () => null),
      );
    }
  });
  axios.interceptors.response.handlers.forEach((h) => {
    const responseInterceptor = get(
      module,
      'moduleAxios.interceptors.response',
      null,
    );
    if (responseInterceptor) {
      responseInterceptor.use(h.fulfilled, h.rejected);
    }
  });
};

export default initLoading;
