import React, { Component } from 'react'; // eslint-disable-line
import Paper from '@material-ui/core/Paper';
import { get } from 'lodash';
import { compose } from 'redux';
import validator from 'validator';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import Button from '@material-ui/core/Button';
import Key from '@material-ui/icons/VpnKey';
import Forward from '@material-ui/icons/ArrowForward';
import AccountCircle from '@material-ui/icons/AccountCircle';

import LangSelectorButtons from './LangSelectorButtons';
import Spinner from './Spinner.tsx';
import history from '../../history';
import cognito from '../cognito';
import { loginSuccess, loginFail } from '../actions';
import LoginResult from './LoginResult';

const styles = () => ({
  wrap: {
    display: 'flex',
    width: '100vw',
    height: '100vh',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: '5vh',
  },
  paper: {
    // width: '40vw',
    padding: '4vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    flexDirection: 'column',
  },
  guide: {
    width: '38vw',
    fontSize: '1.2em',
    textAlign: 'center',
  },
  fieldWrap: {
    width: '80%',
  },
  field: {
    fontSize: '1.5em',
  },
  icon: {
    width: '1.5em',
    height: '1.5em',
  },
  inputIcon: {
    width: '1.8em',
    height: '1.8em',
    paddingTop: '.6em',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2vh',
    width: '100%',
  },
  button: {
    margin: '2.5em 0 0 0',
    fontSize: '1.5em',
  },
  btnWrap: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '90%',
  },
});

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {},
      dialog: {
        show: false,
      },
      spinner: {
        show: false,
      },
    };
  }

  componentDidMount() {
    ValidatorForm.addValidationRule('isAsci', (value) => {
      if (value.length === 0) {
        return true;
      }
      if (!validator.isAscii(value)) {
        return false;
      }
      return true;
    });
  }

  onChange = (val) => {
    const { formData: existingformData } = this.state;

    const formData = { ...existingformData, [val.key]: val.value.trim() };

    this.setState({
      formData,
    });
  };

  submit = async (e) => {
    e.preventDefault();
    const { formData } = this.state;
    const {
      loginSuccess: loginSuccessProp,
      loginFail: loginFailProp,
    } = this.props;

    this.setState({
      spinner: {
        show: true,
      },
    });

    try {
      await cognito.logIn(formData);
      loginSuccessProp(formData);
      this.continue();
    } catch (err) {
      loginFailProp(err.message);
      this.setState({
        dialog: {
          show: true,
          err: err.message,
          errCode: err.code,
        },
        spinner: {
          show: false,
        },
      });
    }
  };

  retry = () => {
    this.setState({
      dialog: {
        show: false,
        err: null,
      },
    });
  };

  continue = () => {
    history.push('/');
    // reload app so that new reducers will take effect
    global.location.reload();
  };

  render() {
    const { classes, intl } = this.props;
    const { formData, dialog, spinner } = this.state;
    const requiredError = intl.formatMessage({ id: 'auth.error.required' });
    const asciError = intl.formatMessage({ id: 'auth.error.asci' });

    return (
      <>
        <LangSelectorButtons />
        <div className={classes.wrap}>
          {spinner.show && <Spinner />}
          <h1>
            <FormattedMessage
              id="auth.logIn"
              default="!!auth.logIn"
              description="Log in"
            />
          </h1>
          <p className={classes.guide}>
            <FormattedMessage
              id="auth.talkingTo"
              default="!!auth.talkingTo"
              description="Talking to"
            />
          </p>
          {dialog.show && (
            <LoginResult
              err={dialog.err}
              errCode={dialog.errCode}
              onRetry={this.retry}
            />
          )}

          <Paper className={classes.paper}>
            <ValidatorForm
              onSubmit={(e) => this.submit(e)}
              noValidate
              autoComplete="off"
            >
              <div className={classes.wrapper}>
                <AccountCircle className={classes.inputIcon} />
                <TextValidator
                  onChange={(val) => {
                    this.onChange({ key: 'username', value: val.target.value });
                  }}
                  className={classes.fieldWrap}
                  value={get(formData, 'username', '')}
                  required
                  InputProps={{ classes: { input: classes.field } }}
                  name="username"
                  validators={['required', 'isAsci']}
                  errorMessages={[requiredError, asciError]}
                  label={
                    <FormattedMessage
                      id="auth.username"
                      default="!!auth.username"
                      description="username"
                    />
                  }
                />
              </div>
              <div className={classes.wrapper}>
                <Key className={classes.inputIcon} />
                <TextValidator
                  onChange={(val) => {
                    this.onChange({ key: 'password', value: val.target.value });
                  }}
                  className={classes.fieldWrap}
                  name="password"
                  value={get(formData, 'password', '')}
                  type="password"
                  required
                  validators={['required', 'isAsci']}
                  errorMessages={[requiredError, asciError]}
                  InputProps={{ classes: { input: classes.field } }}
                  label={
                    <FormattedMessage
                      id="auth.password"
                      default="!!auth.password"
                      description="password"
                    />
                  }
                />
              </div>
              <div className={classes.btnWrap}>
                <Button
                  className={classes.button}
                  color="primary"
                  variant="contained"
                  type="submit"
                >
                  <FormattedMessage
                    id="auth.logIn"
                    default="!!auth.logIn"
                    description="Welcome"
                  />
                  <Forward className={classes.icon} />
                </Button>
              </div>
            </ValidatorForm>
          </Paper>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loginSuccess: (formData) => {
    dispatch(loginSuccess(formData));
  },
  loginFail: (formData) => {
    dispatch(loginFail(formData));
  },
});

export default compose(
  injectIntl,
  withStyles(styles),
  connect(null, mapDispatchToProps),
)(Login);
