import {
  CognitoUserPool,
  // CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
} from 'amazon-cognito-identity-js';

// ToDo: ENV these
const poolData = {
  UserPoolId: 'eu-central-1_qpIn3bffS',
  ClientId: '3puvpbd9ahmv9f4ram1ts3l7i6',
};

const userPool = new CognitoUserPool(poolData);

/*
const signUp = async ({
  username,
  name,
  password,
  email,
}: {
  username: string,
  name: string,
  password: string,
  email: ?string,
}) => {
  const res = await new Promise((resolve, reject) => {
    userPool.signUp(username, password, [
      new CognitoUserAttribute({ Name: 'email', Value: email || '' }),
      new CognitoUserAttribute({ Name: 'name', Value: name  }),
    ], null, (err, result) => {
      if (err) return reject(err);

      return resolve(result.user);
    });
  });

  return res;
};
*/
const logIn = async ({ username, password }) => {
  const authenticationDetails = new AuthenticationDetails({
    Username: username,
    Password: password,
  });

  const userData = {
    Username: username,
    Pool: userPool,
  };

  const cognitoUser = new CognitoUser(userData);

  const res = await new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => resolve(result),
      onFailure: (err) => reject(err),
    });
  });

  return res;
};

const refreshSession = () =>
  new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();

    cognitoUser.getSession((err, data) => {
      if (err) {
        reject(err);
        return;
      }

      if (!data?.isValid() || !data?.getRefreshToken) {
        reject(null);
        return;
      }

      const refreshToken = data.getRefreshToken();

      cognitoUser.refreshSession(refreshToken, (err2, data2) => {
        if (err2) {
          reject(err2);
          return;
        }

        if (!data2?.isValid() || !data2?.getRefreshToken) {
          reject(null);
          return;
        }
        console.log('token refreshed');
        resolve(data2);
      });
    });
  });

export default {
  userPool,
  refreshSession,
  // signUp,
  logIn,
};
