import { updateIntl } from 'react-intl-redux';
import auth from './auth/translations';
import home from './home';
import { store } from './index';

const loadLocaleData = async (locale) => {
  const messages = [auth, home.translations]
    .map((t) => t[locale])
    .filter((t) => t)
    .reduce((a, b) => ({ ...a, ...b }), {});

  store.dispatch(
    updateIntl({
      locale,
      messages,
    }),
  );
};

export default loadLocaleData;
