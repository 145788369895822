import { Plugins, Capacitor } from '@capacitor/core';

import { Diagnostic } from '@ionic-native/diagnostic';
import { Autostart } from '@ionic-native/autostart';

import { BackgroundMode } from '@ionic-native/background-mode';
import { useSelector } from 'react-redux';
import actions from './actionTypes';
import { firebaseToken, connectWS } from './actions';
import { store } from '../index';
import createSocketConnection from './websocket';

const {
  App,
  // BackgroundTask,
  PushNotifications,
  // PushNotification,
  // PushNotificationToken,
  // PushNotificationActionPerformed,
} = Plugins;

if (Capacitor.isNative) {
  console.log('capacitor init');
  App.addListener('backButton', () => {
    console.log('Blocked');
  });
  BackgroundMode.setDefaults({
    channelName: 'Anne Calling background mode',
    channelDescription: 'Anne Calling background mode',
    text: '',
    allowClose: false,
    subText: '',
    closeIcon: 'power', // An icon shown for the close action
    closeTitle: 'Close', // The text for the close action
    visibility: 'public',
    silent: false,
    hidden: false,
    color: '#faa907',
  });

  BackgroundMode.enable();
  BackgroundMode.overrideBackButton();
  BackgroundMode.disableBatteryOptimizations();
  BackgroundMode.disableWebViewOptimizations();
  BackgroundMode.fireEvent('activate');
  BackgroundMode.fireEvent('enable');
  Autostart.enable();
  Autostart.enableService(
    'de.appplant.cordova.plugin.background.ForegroundService',
  );

  setInterval(() => {
    BackgroundMode.disableBatteryOptimizations();
    BackgroundMode.disableWebViewOptimizations();
  }, 30000);

  PushNotifications.removeAllListeners();
  PushNotifications.addListener('registration', async (token) => {
    store.dispatch(firebaseToken(token.value));
    await Diagnostic.requestMicrophoneAuthorization();
    await Diagnostic.requestCameraAuthorization({ externalStorage: false });
    BackgroundMode.requestForegroundPermission();
  });

  PushNotifications.addListener('registrationError', (error) => {
    // alert("Error on registration: " + JSON.stringify(error));
  });

  PushNotifications.addListener('pushNotificationReceived', (notification) => {
    console.log(`Push received: ${JSON.stringify(notification)}`);
  });

  PushNotifications.addListener(
    'pushNotificationActionPerformed',
    (notification) => {
      console.log(`Push action performed: ${JSON.stringify(notification)}`);
    },
  );

  BackgroundMode.on('activate', () => {
    BackgroundMode.disableWebViewOptimizations();
    BackgroundMode.disableBatteryOptimizations();
    BackgroundMode.requestForegroundPermission();
  });

  App.addListener('appStateChange', (state) => {
    if (state.isActive) {
      // remove all notifications when app is in foreground
      PushNotifications.removeAllDeliveredNotifications();
      BackgroundMode.requestForegroundPermission();
    } else {
      BackgroundMode.disableWebViewOptimizations();
    }
  });
}

export const firebaseTokenListener = async () => {
  // listen to lastActionType as not to have an endless loop
  const lastActionType =
    store.getState()?.appsync?.lastActionListener?.type || null;

  if (lastActionType === actions.FIREBASE_TOKEN) {
    const firebaseRegistrationToken =
      store.getState()?.appsync?.lastActionListener?.token || null;
    const oldSocket = store.getState()?.shell?.websocket || null;
    if (oldSocket && oldSocket.close && firebaseRegistrationToken) {
      oldSocket.close();
    }

    console.log('reconnecting');
    createSocketConnection(async (rws) => {
      const socket = await rws;
      store.dispatch(connectWS(socket));
    }, firebaseRegistrationToken);
  }
};

export const BringToForegroundListener = () => {
  const callStatus = useSelector(
    (state) => state?.['video-conferencing']?.callStatus,
  );

  if (Capacitor.isNative) {
    if (callStatus === 'incoming' || callStatus === 'connecting') {
      BackgroundMode.wakeUp();
      BackgroundMode.unlock();
      BackgroundMode.moveToForeground();
    }
  }

  return null;
};
