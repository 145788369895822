import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Components from './components';

const Auth = () => (
  <Switch>
    <Route key="login" exact path="/auth/login" component={Components.Login} />
    {/* <Route exact path="/auth/signup" component={Components.Signup} /> */}
  </Switch>
);

export default Auth;
