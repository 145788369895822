import Component from './Shell';
import actions from './actions';
import reducer from './reducer';
import { start } from './worker';

const init = async ({ /* locale, */ store }) => {
  await start(store);
};
export default {
  Component,
  actions,
  reducer,
  init,
};
