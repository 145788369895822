import React from 'react';
import Button from '@material-ui/core/Button';
// import Add from '@material-ui/icons/AddCircle';
import { useIntl } from 'react-intl';

import Toolbar from '@material-ui/core/Toolbar';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import { makeStyles } from '@material-ui/core/styles';

import i18n from '../../i18n';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  langButton: {
    color: '#0000009c',
    fontSize: '1.1rem',
  },
}));

export default function LangSelectorButtons() {
  const classes = useStyles();
  const intl = useIntl();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeLanguage = async (lang) => {
    await i18n(lang);
    handleClose();
  };

  return (
    <div className={classes.root}>
      <Toolbar>
        <div variant="h6" className={classes.title} />
        <div>
          <Button
            variant="contained"
            onClick={handleMenu}
            className={classes.langButton}
          >
            {intl.locale.toUpperCase()}
          </Button>

          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            <MenuItem
              disabled={intl.locale === 'en'}
              className={classes.langButton}
              onClick={(event) => handleChangeLanguage('en')}
            >
              EN
            </MenuItem>
            <MenuItem
              disabled={intl.locale === 'nl'}
              className={classes.langButton}
              onClick={() => handleChangeLanguage('nl')}
            >
              NL
            </MenuItem>
            <MenuItem
              disabled={intl.locale === 'de'}
              className={classes.langButton}
              onClick={() => handleChangeLanguage('de')}
            >
              DE
            </MenuItem>
            <MenuItem
              disabled={intl.locale === 'it'}
              className={classes.langButton}
              onClick={() => handleChangeLanguage('it')}
            >
              IT
            </MenuItem>
            <MenuItem
              disabled={intl.locale === 'fr'}
              className={classes.langButton}
              onClick={() => handleChangeLanguage('fr')}
            >
              FR
            </MenuItem>
            <MenuItem
              disabled={intl.locale === 'pl'}
              className={classes.langButton}
              onClick={() => handleChangeLanguage('pl')}
            >
              PL
            </MenuItem>
            <MenuItem
              disabled={intl.locale === 'ro'}
              className={classes.langButton}
              onClick={() => handleChangeLanguage('ro')}
            >
              RO
            </MenuItem>
            <MenuItem
              disabled={intl.locale === 'tr'}
              className={classes.langButton}
              onClick={() => handleChangeLanguage('tr')}
            >
              TR
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </div>
  );
}
