import React from 'react';
import { connect } from 'react-redux';
import Modal from '@material-ui/core/Modal';

import { withStyles } from '@material-ui/core/styles';

import { hideCenterModal } from '../actions';

const styles = () => ({
  centerScreen: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none!important',
  },
});

const CenterModal = ({
  classes,
  centerModalComponent,
  disableHideModal,
  hideModal,
}) => (
  <Modal
    open={centerModalComponent && true}
    {...(!disableHideModal && { onClose: hideModal })}
  >
    <div className={classes.centerScreen}>{centerModalComponent}</div>
  </Modal>
);

const mapStateToProps = (state) => ({
  centerModalComponent: state.shell.centerModalComponent,
  disableHideModal: state.shell.disableHideModal,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => {
    dispatch(hideCenterModal());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(CenterModal));
