import hat from 'hat';
import { uniqBy } from 'lodash';
import actionTypes from './actionTypes';
import { shellDB } from './worker';

const initialState = {
  errors: [],
  microphoneActive: false,
  notifications: [],
  centerModalComponent: false,
  disableHideModal: false,
  loading: false,
  triggerTellTime: '',
  triggerTellDate: '',
  triggerTelemetry: '',
  websocket: null,
  websocketConnected: false,
  videoConferencingInitialized: false,
  firebaseToken: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.VIDEO_CONFERENCING_INITIALIZED: {
      return { ...state, videoConferencingInitialized: true };
    }

    case actionTypes.WS_CONNECTED: {
      return { ...state, websocket: action.websocket };
    }

    case actionTypes.REMOVE_ERROR: {
      const stateWithRemovedError = state.errors.filter(
        (e) => e.id !== action.id,
      );
      shellDB.errors.put({ id: 'errors', errors: stateWithRemovedError });
      return { ...state, errors: stateWithRemovedError };
    }

    case actionTypes.CACHED_ERRORS: {
      const mergedErrors = [...state.errors, ...action.errors];
      const dedupedErrors = uniqBy(mergedErrors, (err) => err.id);
      shellDB.errors.put({ id: 'errors', errors: dedupedErrors });
      return { ...state, errors: dedupedErrors };
    }

    case actionTypes.LOG_ERROR: {
      const mergedErrors = [...state.errors, ...[action.error]];
      const dedupedErrors = uniqBy(mergedErrors, (err) => err.id);
      shellDB.errors.put({ id: 'errors', errors: dedupedErrors });
      return { ...state, errors: dedupedErrors };
    }
    case actionTypes.TOGGLE_MICROPHONE:
      return {
        ...state,
        microphoneActive: action.microphoneActive // explicit switch
          ? action.microphoneActive
          : !state.microphoneActive, // auto switch
      };
    case actionTypes.SHOW_NOTIFICATION: {
      const nextNotificationState = [
        ...state.notifications,
        action.notification,
      ]
        .map((n) => {
          if (n.priority) {
            return n;
          }

          // If priority isn't explicitly set, make it lowest
          return { ...n, priority: 99 };
        })
        .sort((a, b) => a.priority - b.priority);

      return { ...state, notifications: nextNotificationState };
    }
    case actionTypes.EXPIRE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          (n) =>
            (!n.id || n.id !== action.notificationId) &&
            (!action.notificationType ||
              n.notificationType !== action.notificationType),
        ),
      };
    case actionTypes.CLOSE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          (n) => n.id !== action.notificationId,
        ),
      };
    case actionTypes.SHOW_LOADING_BAR:
      return { ...state, loading: true };
    case actionTypes.HIDE_LOADING_BAR:
      return { ...state, loading: false };
    case actionTypes.SHOW_CENTER_MODAL:
      return {
        ...state,
        centerModalComponent: action.centerModalComponent,
        disableHideModal: action.disableHideModal,
      };
    case actionTypes.HIDE_CENTER_MODAL:
      return { ...state, centerModalComponent: false, disableHideModal: false };
    case actionTypes.ASR_TELL_TIME:
      return { ...state, triggerTellTime: hat() };
    case actionTypes.ASR_TELL_DATE:
      return { ...state, triggerTellDate: hat() };
    case actionTypes.TELEMETRY_RECORD:
      return { ...state, triggerTelemetry: hat(), telemetryData: action.data };

    case actionTypes.FIREBASE_TOKEN:
      return { ...state, firebaseToken: action.token };

    case actionTypes.WEBSOCKET_CONNECTED:
      return {
        ...state,
        websocketConnected: true,
      };
    case actionTypes.WEBSOCKET_FAILED:
      return {
        ...state,
        websocketConnected: false,
      };
    default:
      return { ...state };
  }
};
