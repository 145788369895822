import React from 'react'; // eslint-disable-line
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles, createStyles } from '@material-ui/core/styles';

const styles = createStyles({
  dContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  item: {
    margin: '2em 0',
  },
});

interface SpinnerProps {
  classes: {
    dContent: string;
    item: string;
  };
}

const Spinner = ({ classes }: SpinnerProps): JSX.Element => (
  <Dialog open>
    <DialogContent>
      <div className={classes.dContent}>
        <CircularProgress className={classes.item} />
        <FormattedMessage
          id="auth.pleaseWait"
          description="Please wait auth message"
        />
      </div>
    </DialogContent>
  </Dialog>
);

export default compose(injectIntl, withStyles(styles))(Spinner);
