import { combineReducers } from 'redux';
import { intlReducer } from 'react-intl-redux';

import Shell from './shell';
import Auth from './auth';
import AppSync from './appsync';
import Home from './home';

const reducers = {
  shell: Shell.reducer,
  auth: Auth.reducer,
  appsync: AppSync.reducer,
  [Home.ID]: Home.reducer,
  intl: intlReducer,
};

export default combineReducers(reducers);
