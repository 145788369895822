import {
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
} from './actionTypes';

const initialState = {
  cre: {},
  err: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SIGNUP_SUCCESS:
      return {};
    case SIGNUP_FAIL:
      return {};
    case LOGIN_SUCCESS:
      return { ...state, cre: action.formData, err: null };
    case LOGIN_FAIL:
      localStorage.clear();
      return { ...initialState, err: action.err };
    default:
      return { ...state };
  }
};
