import { get } from 'lodash';
// import dummy from 'client/dummy';
// import news from 'client/news';
import shell from './shell';
// import radio from 'client/radio';
// import agenda from 'client/agenda';
import home from './home';
// import medications from 'client/medications';
// import album from 'client/album';
// import games from 'client/games';
import { store as reduxStore } from './index';
import history from './history';
import { applyModuleAxiosInterceptors } from './shell/initLoading';

const modules = [
  // dummy,
  // news,
  // radio,
  // agenda,
  home,
  // medications,
  // album,
  // games,
];

const getModules = async (init = false, store = {}, enabledModules = []) => {
  // const settings = await Auth.selectors.getUserSettings();
  // const modArray = Object.keys(settings.modules);
  // const modArray = ['dummy', 'news', 'radio', 'agenda'];
  // const modArray = [/* 'dummy', */'news', 'radio', 'agenda', 'video-conferencing'];
  // modArray.push('dummy');
  if (init && enabledModules && enabledModules.length > 0) {
    const enabled = modules.filter((m) => enabledModules.includes(m.ID));
    shell.init({
      locale: process.env.REACT_APP_LOCALE,
      store,
      history,
    });
    const initPromises = enabled.map((m) =>
      m.init({
        locale: process.env.REACT_APP_LOCALE,
        store,
        history,
      }),
    );

    await Promise.all(initPromises);
    // enabled.forEach(m => m.init({
    //   locale: process.env.REACT_APP_LOCALE,
    //   store,
    //   history,
    // }));
    return enabled;
  }

  const enabledModulesAfterInit = get(
    reduxStore.getState(),
    'appsync.appSyncData.userSettings',
    [
      {
        modules: [],
      },
    ],
  ).modules;

  if (enabledModulesAfterInit && enabledModulesAfterInit.length > 0) {
    const enabled = modules.filter((m) =>
      enabledModulesAfterInit.includes(m.ID),
    );
    enabled.forEach((m) => applyModuleAxiosInterceptors(m));
    return enabled;
  }

  return [];
};

export default {
  getModules,
  modules,
};
