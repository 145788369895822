import { USER_DATA_SUCCESS, USER_DATA_FAIL } from './actionTypes';

const getAllUserSettingsSuccess = (userSettings, disableListener) => ({
  type: USER_DATA_SUCCESS,
  userSettings,
  disableListener,
});

const getAllUserSettingsFail = (userSettings) => ({
  type: USER_DATA_FAIL,
  userSettings,
});

export { getAllUserSettingsSuccess, getAllUserSettingsFail }; //eslint-disable-line
