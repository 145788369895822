import actions from './actionTypes';

export const firebaseToken = (token) => ({
  type: actions.FIREBASE_TOKEN,
  token,
});

export const initVideoConferencing = () => ({
  type: actions.VIDEO_CONFERENCING_INITIALIZED,
});

export const errorToRedux = (error) => ({
  type: actions.LOG_ERROR,
  error,
});

export const connectWS = (websocket) => ({
  type: actions.WS_CONNECTED,
  websocket,
});

export const websocketConnected = () => ({
  type: actions.WEBSOCKET_CONNECTED,
});

export const websocketFailed = () => ({
  type: actions.WEBSOCKET_FAILED,
});

export const cachedErrorsToRedux = (errors) => ({
  type: actions.CACHED_ERRORS,
  errors,
});

export const removeError = (id) => ({
  type: actions.REMOVE_ERROR,
  id,
});

export const showLoadingBar = () => ({
  type: actions.SHOW_LOADING_BAR,
});

export const hideLoadingBar = () => ({
  type: actions.HIDE_LOADING_BAR,
});

export const showCenterModal = (component, hideIsDisabled) => {
  if (hideIsDisabled) {
    return {
      type: actions.SHOW_CENTER_MODAL,
      centerModalComponent: component,
      disableHideModal: true,
    };
  }
  return {
    type: actions.SHOW_CENTER_MODAL,
    centerModalComponent: component,
    disableHideModal: false,
  };
};

export const hideCenterModal = () => ({
  type: actions.HIDE_CENTER_MODAL,
});

export default {
  cachedErrorsToRedux,
  removeError,
  errorToRedux,
  showLoadingBar,
  hideLoadingBar,
  showCenterModal,
  hideCenterModal,
  connectWS,
  websocketConnected,
  websocketFailed,
  firebaseToken,
};
