import hat from 'hat';
import { store } from '../index';
import { errorToRedux } from './actions';
// import pkg from '../../../package.json';

// eslint-disable-next-line
const dispatchErrorToRedux = ({ err, sourceApp, partOfApp }) => {
  if (process.env.NODE_ENV === 'production') {
    store.dispatch(
      errorToRedux({
        err,
        sourceApp,
        // partOfApp: `${partOfApp}_${pkg.version}`,
        id: hat(),
      }),
    );
  }
};

export default dispatchErrorToRedux;
