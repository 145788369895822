import React from 'react';
import { default as videoConferencingMain } from '@virtaskdev/module-video-conferencing';

const VideoConferencingReactImport = React.lazy(
  () =>
    import(
      '@virtaskdev/module-video-conferencing/dist/component-video-conferencing'
    ),
);
// eslint-disable-next-line
const videoConferencing = {
  ...videoConferencingMain,
  component: () => (
    <React.Suspense fallback={<div />}>
      <VideoConferencingReactImport />
    </React.Suspense>
  ),
};

export default videoConferencing;
