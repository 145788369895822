import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import 'typeface-roboto';
import AccountArrowLeft from 'mdi-material-ui/AccountArrowLeft';
import { initVideoConferencing } from './actions';
import history from '../history';
import videoConferencing from '../home';
import Auth from '../auth';
import modules from '../modules';
import { store } from '../index';
import initLoading from './initLoading';
import { BringToForegroundListener } from './capacitorInit';
import CenterModal from './components/CenterModal';
import ErrorBoundary from './components/ErrorBoundary';
import { removeCache } from '../utils';

import './shell.css';

const styles = () => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  modules: {
    height: '100vh',
  },
});

const Shell = ({
  initLoadingProp,
  classes,
  loading,
  enabledModules,
  videoConferencingInitialized,
}) => {
  React.useEffect(() => {
    const onMount = async () => {
      initLoadingProp();
      await Auth.selectors.getUserSettings();
    };
    onMount();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const initModule = () => {
      modules.getModules(true, store, enabledModules);
    };
    if (
      !videoConferencingInitialized &&
      enabledModules.includes(videoConferencing.ID)
    ) {
      initModule();
      store.dispatch(initVideoConferencing());
    }
    // eslint-disable-next-line
  }, [enabledModules.includes(videoConferencing.ID)]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleMenuLogout() {
    setAnchorEl(null);
    history.push('/auth/login');
    removeCache();
    const closeWS = store.getState()?.shell?.websocket?.close;
    if (closeWS) {
      closeWS();
    }
  }

  return (
    <div className={classes.root}>
      <ErrorBoundary>
        <div className={classes.placeholder}>
          <Fade in={loading} unmountOnExit>
            <LinearProgress />
          </Fade>
        </div>
        <ErrorBoundary>
          <CenterModal />
        </ErrorBoundary>
        <ErrorBoundary>
          <BringToForegroundListener />
        </ErrorBoundary>

        <div className={classes.main}>
          <div className={classes.modules}>
            <div
              style={{
                padding: '3.6vh 2vh 2vh 1vh',
                position: 'absolute',
                right: 0,
              }}
            >
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <AccountArrowLeft
                  style={{
                    fontSize: '3.4em',
                  }}
                />
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleMenuLogout}>Logout</MenuItem>
              </Menu>
            </div>

            <ErrorBoundary>
              {enabledModules.includes(videoConferencing.ID) && (
                <videoConferencing.component />
              )}
            </ErrorBoundary>
          </div>
        </div>
      </ErrorBoundary>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.shell.loading,
  enabledModules: state.appsync.appSyncData.userSettings.modules,
  videoConferencingInitialized: state.shell.videoConferencingInitialized,
});

const mapDispatchToProps = (dispatch) => ({
  initLoadingProp: () => {
    initLoading(dispatch);
  },
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Shell));
